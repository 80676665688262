import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pagePhotographierVoieLactee: allPagesCompilationYaml(
      filter: { code: { eq: "photographierLaVoieLactee" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "traitementImagesVoieLactee"
            "voieLacteeIntegralite360"
            "galerieVoieLactee"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeIntegralite", "bulbeZetaOphiuchi"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "voieLacteeMateriel"
            "voieLacteeCarteBulbe"
            "m8m20LaguneTrifide"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["voieLacteeBouee", "voieLacteePhare"] } }
    ) {
      ...FragmentImagePortraitMoyen
    }
    imagesPortraitPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: ["voieLacteeWindy", "materielUnemontureEquatorialeStarAdventurer"]
        }
      }
    ) {
      ...FragmentImagePortraitPetit
    }
  }
`

export default function PhotographierLaVoieLactee() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pagePhotographierVoieLactee")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPortraitMoyen",
    "imagesPortraitPetit"
  )

  /*nécessaire si le type JSONLD de la page est défini comme étant "FAQ"*/
  page.jsonLDFAQ = [
    {
      question: "Comment repérer la Voie lactée ?",
      reponse:
        "Bien que la Voie lactée soit visible une grande partie de l’année en France, son bulbe n’est visible qu’à partir de la mi-avril en fin de nuit, jusqu’à mi-septembre en début de nuit. Pour le trouver, repérez les constellations du Scorpion et du Sagittaire sur l’horizon sud.",
    },
    {
      question: "Où photographier la Voie lactée ?",
      reponse:
        "Vous devez repérer un endroit bien épargné par la pollution lumineuse sur l’horizon sud pour réussir vos clichés de la Voie lactée et de son bulbe.",
    },
    {
      question: "Comment surveiller la météo ?",
      reponse:
        "Bien sûr, évitez les nuages. Multipliez vos sources d’informations météo afin d’obtenir une prévision fiable. Je vous conseille de consulter 3 modèles météo différents avant de planifier une sortie astronomique.",
    },
    {
      question: "Quel appareil photo utiliser ?",
      reponse:
        "N’importe quel appareil permettant de faire des poses suffisamment longues peut faire l’affaire. Mais pour ce sujet très exigeant, un appareil réflex ou hybride sera plus performant car souvent moins bruité dans les hautes sensibilités.",
    },
    {
      question: "Quel objectif choisir ?",
      reponse:
        "Tout dépend des photos que vous souhaiterez prendre. Évitez les focales trop longues (plus de 135 mm) car la Terre tourne et sans dispositif de suivi, vos étoiles deviendront ovales en moins de 5s. Un objectif ouvert à F2.8 ou moins est idéal.",
    },
    {
      question: "Quels accessoires ?",
      reponse:
        "Un trépied est indispensable, ainsi qu’une télécommande pour ne pas faire vibrer l’appareil lors du déclenchement (utilisez le retardateur sinon). Pour les panoramas, une rotule panoramique vous sera utile.",
    },
    {
      question: "Quels réglages utiliser ?",
      reponse:
        "Vérifiez que votre boîtier est en mode RAW. Sinon, la compression des images va détruire les étoiles les plus faibles et votre photo perdra des détails. Ouvrez votre objectif au maximum. Déterminez ensuite le réglage ISO : sur mon EOS 6D, je reste la plupart du temps à 3200 ISO. Dernier réglage : le temps de pose. Vous pouvez obtenir une bonne indication du temps de pose maximal à l’aide de la règle des « 500 » : elle est égale à 500 divisé par la longueur focale de votre objectif. Ainsi, à 17mm : 500/17 = 29 secondes.",
    },
    {
      question: "Comment faire la mise au point ?",
      reponse:
        "Le meilleur moyen est de viser une étoile brillante et de la grossir au maximum avec le liveview. Faites ensuite la mise au point manuellement : l’étoile doit devenir aussi petite que possible.",
    },
  ]

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          C’est une nuit d’été magnifique. Vous levez les yeux. La Lune n’est
          pas encore levée. Au zénith, un ruban d’étoiles s’étire du nord au sud
          et scintille de mille feux. C’est la Voie lactée, notre galaxie. Son
          nom nous viendrait de la mythologie grecque. Zeus, en voulant rendre
          son fils Hercules immortel, lui fit téter le lait de sa femme Héra
          pendant qu’elle dormait. Lorsqu’elle arracha Hercules de son sein, une
          giclée de lait se répandit dans le ciel et forma la Voie lactée.
        </p>
        <p>
          Comme les milliards de galaxies de son genre, la Voie lactée possède
          une forme de disque. Comme nous vivons « dedans », nous le voyons par
          la tranche. Le diamètre de la Voie lactée est estimé à{" "}
          <strong>100&nbsp;000 années lumière</strong> (10^17 km !), et elle
          compte environ 100 milliards d’étoiles. Sous un ciel très noir, ce
          fourmillement d’étoiles, dont la lumière a mis plusieurs centaines
          d’années à nous parvenir, peut projeter votre ombre au sol. Cette
          ombre vient donc du passé. Il en sera de même lorsque vous
          photographierez la Voie lactée : vous réaliserez des clichés… du
          passé.
        </p>
        <Image
          id="voie-lactee-integralite"
          image={obtenirImage(images, "voieLacteeIntegralite")}
          codePageCible="voieLacteeIntegralite360"
          langue={page.langue}
        />
      </Section>
      <Section titre="Le bulbe de la Voie lactée">
        <p>
          Le bulbe (ou « noyau ») est la région de la Voie lactée la plus
          spectaculaire à observer et à photographier. Située entre les
          constellations du Scorpion et du Sagittaire,{" "}
          <strong>
            c’est une région riche en étoiles, en nébuleuses colorées et en
            nuages de poussières sombres
          </strong>
          .
        </p>
        <Image
          id="bulbe-zeta-ophiuchi"
          image={obtenirImage(images, "bulbeZetaOphiuchi")}
          langue={page.langue}
        />
        <p>
          Mais depuis la France, nous n’en voyons qu’une moitié. Pourquoi ? Car
          quel que soit l’endroit où l’on se trouve sur la planète,{" "}
          <strong>
            il n’est pas possible de voir la Voie lactée en entier
          </strong>
          . Ainsi, en France, la Voie lactée est « coupée » par l’horizon sud au
          niveau du bulbe galactique. Si vous avez donc la chance de voyager
          vers l’hémisphère sud, vous pourrez admirer le bulbe en entier car il
          sera plus haut dans le ciel. Ne manquez alors pas l’occasion de vous
          isoler sous des cieux bien noirs afin de le photographier.
        </p>
      </Section>
      <Section titre="Du noir et blanc… à la couleur !">
        <p>
          Si la Voie lactée vous apparaît à l’œil nu en noir et blanc, vous
          aurez la surprise de constater que votre appareil photo, beaucoup plus
          sensible, la voit en couleurs ! Surtout si vous le braquez en
          direction du bulbe galactique, riche en nébulosités colorées et en
          vieilles étoiles dorées.
        </p>
      </Section>
      <Section titre="Préparez votre sortie">
        <h3>Comment repérer la Voie lactée ?</h3>
        <p>
          Bien que la Voie lactée soit visible une grande partie de l’année en
          France, son bulbe n’est visible qu’à partir de la mi-avril en fin de
          nuit, jusqu’à mi-septembre en début de nuit. Pour le trouver, repérez
          les constellations
          <strong> du Scorpion et du Sagittaire </strong> (voir carte
          ci-dessous) sur l’horizon sud. Vous devrez aussi attendre que{" "}
          <strong>la Voie lactée soit haute</strong> dans le ciel (au moins 60°
          de hauteur). Vérifiez enfin les heures de lever et de coucher de la{" "}
          <strong>Lune</strong> car elle ne devra pas être levée quand vous
          prendrez vos clichés. À l’aide de logiciels planetarium comme{" "}
          <Lien urlExterne="https://stellarium.org">Stellarium</Lien> ou{" "}
          <Lien urlExterne="https://www.ap-i.net/skychart">Cartes du Ciel</Lien>
          , vous pourrez facilement simuler tous ces paramètres.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeCarteBulbe")}
          langue={page.langue}
        />
        <h3>Où photographier la Voie lactée ?</h3>
        <p>
          Vous devez repérer un endroit bien{" "}
          <strong>épargné par la pollution lumineuse</strong> sur l’horizon sud
          pour réussir vos clichés de la Voie lactée et de son bulbe. Pour cela,
          une carte de pollution lumineuse (faites une recherche sur internet)
          vous sera d’une aide précieuse. Les meilleurs endroits en France pour
          réaliser ces clichés sont les régions montagneuses (à condition de
          monter sur les hauteurs) ou encore la Bretagne du sud. Les zones
          faiblement peuplées sont aussi très intéressantes (Drôme, Quercy…).
          Cependant, si vous n’avez pas accès à un horizon sud dégagé,{" "}
          <strong>la partie zénithale de la Voie lactée</strong> est aussi très
          intéressante et fourmille d’étoiles, surtout en été.
        </p>
        <h3>Comment surveiller la météo ?</h3>
        <p>
          <strong>Bien sûr, évitez les nuages</strong>. Multipliez vos sources
          d’informations météo afin d’obtenir une prévision fiable. Je vous
          conseille de consulter <strong>3 modèles météo différents</strong>{" "}
          avant de planifier une sortie astronomique. En voici certains :{" "}
          <Lien urlExterne="https://www.meteociel.fr">www.meteociel.fr</Lien>,{" "}
          <Lien urlExterne="https://wwww.meteoblue.ch">www.meteoblue.ch</Lien>{" "}
          et bien sûr{" "}
          <Lien urlExterne="https://www.meteo.fr">www.meteo.fr</Lien>. Le site{" "}
          <Lien urlExterne="https://www.windy.com">Windy</Lien> propose
          également des prévisions de couverture nuageuse interactives très
          utiles.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeWindy")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Quel matériel utiliser ?">
        <h3>Quel appareil photo utiliser ?</h3>
        <p>
          N’importe quel appareil permettant de faire{" "}
          <strong>des poses suffisamment longues</strong> peut faire l’affaire.
          Mais pour ce sujet très exigeant, un appareil réflex ou hybride sera
          plus performant car souvent moins bruité dans les hautes sensibilités.
          Le must est le réflex dit « défiltré ». En effet, le rouge intense de
          certaines nébuleuses est coupé par un filtre placé devant le capteur
          de votre appareil. Certaines sociétés se proposent d’opérer votre
          boîtier et de remplacer ce filtre par un autre, plus tolérant.
          Attention, après la modification, vous risquez de perdre la garantie
          constructeur.
        </p>
        <h3>Quel objectif choisir ?</h3>
        <p>
          Tout dépend des photos que vous souhaiterez prendre. Évitez les{" "}
          <strong>focales trop longues</strong> (plus de 135 mm) car la Terre
          tourne et sans dispositif de suivi, vos étoiles deviendront ovales en
          moins de 5 secondes. Un objectif ouvert à{" "}
          <strong>F2.8 ou moins est idéal</strong>. Une optique moins lumineuse
          peut convenir, à condition de monter en sensibilité ou de multiplier
          les poses.
        </p>
        <h3>Quels accessoires ?</h3>
        <p>
          Un trépied est indispensable, ainsi qu’une télécommande{" "}
          <strong>pour ne pas faire vibrer l’appareil</strong> lors du
          déclenchement (utilisez le retardateur sinon). Pour les panoramas, une
          rotule panoramique vous sera utile. Bien sûr, n’oubliez pas une
          deuxième batterie, votre lampe frontale (avec un éclairage rouge pour
          ne pas vous éblouir). Enfin, couvrez-vous bien, car même l’été, les
          nuits peuvent être fraîches.
        </p>
        <Image
          image={obtenirImage(images, "voieLacteeMateriel")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Paysages et Voie lactée">
        <h3>Quels réglages utiliser ?</h3>
        <p>
          Vérifiez que votre boîtier est en mode <strong>RAW</strong>. Sinon, la
          compression des images va détruire les étoiles les plus faibles et
          votre photo perdra des détails. Ouvrez votre objectif au maximum.
          Déterminez ensuite le réglage ISO : sur mon EOS 6D, je reste la
          plupart du temps à 3200 ISO.
        </p>
        <p>
          Dernier réglage : le temps de pose. Comme la Terre tourne sur
          elle-même, <strong>plus vous utiliserez une focale longue</strong>,
          plus le temps de pose doit être court pour obtenir des étoiles qui ne
          soient pas trop ovales. Vous devez vous fixer une tolérance. Avec le
          14 mm F2.8 j’utilise un temps de pose de 25 secondes. Avec le 135 mm L
          F2, le temps de pose que j’utilise chute à… 5 secondes.
        </p>
        <p>
          Vous pouvez obtenir une bonne indication du temps de pose maximal à
          l’aide de la <strong>règle des « 500 »</strong> : elle est égale à 500
          divisé par la longueur focale de votre objectif. Ainsi, à 17 mm :
          500/17 = 29 secondes.
        </p>

        <h3>Comment faire la mise au point ?</h3>
        <p>
          Le meilleur moyen est de viser une étoile brillante et de la grossir
          au maximum avec le liveview. Faites ensuite la mise au point
          manuellement :{" "}
          <strong>l’étoile doit devenir aussi petite que possible</strong>.
          Soyez exigeant si vous utilisez une optique très ouverte (moins de
          2.8) : la mise au point est très délicate et il y a un risque pour que
          votre photo soit floue.{" "}
          <strong>
            Attention, la position « infini » de votre objectif est rarement le
            bon réglage.
          </strong>
        </p>
        <p>
          <strong>
            Si vous souhaitez avoir un premier plan proche et net :
          </strong>
        </p>
        <ul>
          <li>
            Faites la mise au point sur ce premier plan en vous aidant d’une
            lampe torche.
          </li>
          <li>
            Privilégiez une optique avec une courte distance focale (ex : 14 mm)
            pour avoir à la fois le premier plan net et les étoiles nettes : la
            distance d’
            <Lien urlExterne="https://fr.wikipedia.org/wiki/Hyperfocale">
              hyperfocale
            </Lien>{" "}
            est comprise entre 2 et 3 mètres pour un objectif de 14 mm ouvert à
            F2.8, contre environ 13 mètres pour un 24 mm ouvert à F1.4.
          </li>
        </ul>
        <h3>Composition</h3>
        <p>
          Pensez à toujours <strong>relier le ciel et la Terre</strong> :
          incluez toujours au moins un élément terrestre dans votre composition.
          Et bien sûr, appliquez les règles élémentaires de composition d’une
          photographie (règles des tiers, lignes de forces, etc.).
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "voieLacteeBouee")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "voieLacteePhare")}
            langue={page.langue}
          />
        </div>
        <h3>Prenez une série de photos identiques pour diminuer le bruit</h3>
        <p>
          Si vous comptez <strong>prendre un peu de temps</strong> pour traiter
          votre image sur ordinateur, prenez une série de photos identiques
          (même cadrage, même temps de pose, même sensibilité) pour diminuer le
          bruit de l’image finale. En effet, vous pourrez les empiler pour
          simuler une exposition plus longue.
        </p>
        <h3>Traitement de l’image</h3>
        <p>
          La prise de vue réalisée, il vous faut traiter votre image sur PC. Je
          vous invite à lire l’article suivant :{" "}
          <Lien codePageCible="traitementImagesVoieLactee">
            traitement des images de la Voie lactée
          </Lien>
          .
        </p>
        <h3>Problèmes et solutions</h3>
        <ul>
          <li>
            <strong>Ma photo est sous exposée ou trop bruitée</strong> :
            augmentez le temps de pose, l’ouverture, ou la sensibilité ISO. Vous
            pouvez aussi multiplier les prises de vues pour les empiler avec un
            logiciel de traitement d’images et ainsi diminuer le bruit.
          </li>
          <li>
            <strong>La nuit est claire, mais mes photos sont « orange »</strong>{" "}
            : votre site est probablement touché par la pollution lumineuse.
            Vous pouvez retoucher votre photo dans Photoshop (en diminuant la
            saturation de la zone concernée), mais rien ne remplacera un ciel
            pur. Attention toutefois, au niveau de l’horizon, vous n’obtiendrez
            jamais de ciel noir.
          </li>
        </ul>
      </Section>
      <Section titre="Un « zoom » dans la Voie lactée">
        <p>
          En réalisant un « zoom » dans certaines régions bien ciblées de la
          Voie lactée, vous révélerez les couleurs et les détails de notre ciel
          étoilé. On aborde alors le (très) vaste sujet de l’astrophotographie
          du ciel profond…
        </p>
        <Image
          image={obtenirImage(images, "m8m20LaguneTrifide")}
          langue={page.langue}
        />
        <h3>Le matériel</h3>
        <p>
          Pour réaliser ce type de cliché, vous devez vous équiper d’une{" "}
          <strong>monture équatoriale</strong>. Il s’agit d’un système motorisé
          permettant de suivre le déplacement des étoiles dans le ciel. Une fois
          alignée vers le pôle nord céleste (ou sud pour les habitants de
          l’hémisphère sud), une monture équatoriale permet de lancer de longues
          poses photographiques sans que les étoiles ne « filent ». Celles-ci
          restent ponctuelles, même avec des focales importantes et des temps
          d’exposition importants. Je vous recommande pour débuter d’opter pour
          une petite monture équatoriale pouvant se monter sur votre trépied
          photographique : Skywatcher Star Adventurer et Star Adventurer Mini,
          iOptron Skytracker, Vixen Polarie…
          <strong> Elles coûtent entre 150 et 400 €.</strong> Côté optique,
          armez-vous d’un objectif d’au moins <strong>50 mm</strong> et
          suffisamment ouvert (F4 maximum).
        </p>
        <Image
          image={obtenirImage(
            images,
            "materielUnemontureEquatorialeStarAdventurer"
          )}
          langue={page.langue}
        />
        <h3>Quelques idées de cibles</h3>
        <ul>
          <li>
            <strong>En hiver</strong> : la constellation d’Orion.
          </li>
          <li>
            <strong>En été</strong> : le bulbe de la Voie lactée et les
            nébuleuses de la Trifide (M20) et de la Lagune (M8).
          </li>
          <li>
            <strong>Toujours en été</strong> : la constellation du Cygne (au
            zénith).
          </li>
          <li>
            <strong>Fin d’été / début de l’automne</strong> : la grande galaxie
            d’Andromède (M31).
          </li>
          <li>
            <strong>En automne</strong> : l’amas ouvert des Pléiades (M45).
          </li>
        </ul>
        <p>
          Attention, certaines cibles (Orion, M31, M45) se trouvent en
          périphérie du disque de la Voie lactée. Aidez-vous d’un logiciel
          planétarium pour les repérer comme Cartes du ciel.
        </p>
        <h3>Sur le terrain</h3>
        <p>
          Avant d’aligner votre monture équatoriale sur le pôle céleste de votre
          hémisphère (la mise en station), vous devez{" "}
          <strong>équilibrer au mieux</strong> votre équipement. Tournez les
          axes de votre monture et vérifiez qu’il n’y a pas de balourd
          important. Si vous utilisez un objectif lourd avec une monture légère,{" "}
          <strong>
            il est probable que vous ne puissiez pas équilibrer parfaitement
          </strong>{" "}
          votre montage : vous devrez <strong>raccourcir</strong> les poses si
          vous rencontrez des problèmes de suivi (étoiles ovales au centre de
          l’image) Ceci fait, procédez à la mise en station.
        </p>
        <p>
          Réalisez ensuite une mise au point manuelle sur une étoile brillante
          puis cherchez l’objet ciblé. Cette étape peut être un peu laborieuse,
          surtout si vous utilisez un objectif de grande longueur focale et que
          l’objet n’est pas visible à l’œil nu.
        </p>
        <p>
          Si vous pensez être sur votre cible, serrez les freins de votre
          monture <strong>sans exagérer</strong> car vous aurez probablement à
          les desserrer pour corriger votre cadrage, ce qui fera bouger votre
          montage et votre mise en station. Augmentez le réglage ISO de votre
          appareil (12&nbsp;800 par exemple) et lancez une pause de quelques
          secondes. Si vous ne la voyez pas, il est probable que vous ayez visé
          à côté… Une fois que tout est bon, serrez les freins (inutile de
          serrer trop fort), baissez votre réglage ISO et lancez votre
          acquisition avec les temps de pose unitaires indiqués ci-dessous.
          Accumulez-en le plus possible, 2 à 3 heures de pose est une très bonne
          base !
        </p>
        <h3>Le temps de pose</h3>
        <p>
          Le temps de pose unitaire à utiliser dépend de beaucoup de facteurs.
          Le principal d’entre eux est la longueur focale de l’objectif avec
          lequel vous travaillez.
        </p>
        <p>Pour une petite monture, retenez les valeurs suivantes :</p>
        <ul>
          <li>
            <strong>50 mm et moins</strong> : 2 minutes.
          </li>
          <li>
            <strong>Entre 50mm et 150 mm</strong> : 1 minute.
          </li>
          <li>
            <strong>150 mm et plus</strong> : 30 secondes.
          </li>
        </ul>
        <h3>Traitement de l’image</h3>
        <p>
          Réalisez darks et flats, puis combinez-les avec vos poses unitaires
          sur ordinateur pour obtenir l’image finale à l’aide d’un logiciel
          comme{" "}
          <Lien urlExterne="http://deepskystacker.free.fr/french/index.html">
            Deep Sky Stacker
          </Lien>
          . Pour tous les détails, je vous invite à consulter mon livre
          électronique :{" "}
          <Lien codePageCible="astrophotographieReflex">
            Astrophotographie avec un réflex
          </Lien>
          .
        </p>
      </Section>
    </LayoutTutorielFR>
  )
}
